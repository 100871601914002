<template>
  <div>
    <!-- header banner -->
    <page-header></page-header>
    <!-- form -->
    <div class="d-flex justify-center ma-7 ma-lg-12 mb-lg-8">
      <v-card flat color="transparent">
        <v-card-text class="pa-0">
          <form-template
            v-model="modelObj"
            :fields="fields"
            ref="enquiryForm"
          ></form-template>
        </v-card-text>
        <v-card-actions class="pa-1">
          <v-spacer></v-spacer>
          <v-btn color="success darken-1" class="text-capitalize px-3 py-2" @click="submit"> Submit </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      modelObj: {}
    }
  },
  components: {
    'page-header': () => import('@/components/PageHeader.vue'),
    'form-template': () => import('@/components/FormTemplate.vue')
  },
  computed: {
    fields () {
      return [{
        type: 'TEXT',
        model: 'name',
        label: 'Name',
        rules: this.$_requiredValidation,
        class: 'lg8',
      }, {
        type: 'TEXT',
        model: 'phone',
        label: 'Phone number',
        rules: this.$_requiredValidation,
        class: 'lg8',
      }, {
        type: 'TEXTAREA',
        model: 'message',
        label: 'Query/ Message',
        rules: this.$_requiredValidation,
        rows: 5,
        class: 'lg8',
      }]
    }
  },
  methods: {
    submit () {
      if (!(this.$refs.enquiryForm && this.$refs.enquiryForm.$refs.form.validate())) {
        this.$root.$emit('snackbar', { color: 'error', text: 'Please check all the required fields!' })
        return
      }

      this.$_execute('post', 'user-queries', this.modelObj)
        .then(() => {
          this.$root.$emit('snackbar', { color: 'success', text: 'Message sent successfully!' })
          this.modelObj = {}
          this.$refs?.enquiryForm.$refs?.form.reset()
        })
    }
  }
}
</script>
